import cloneDeep from 'lodash/cloneDeep';
import { Providers, Entities } from '@flightscope/baseball-stats';
import { GenericStrikeZone } from '@/enums/Generic';
import { Layouts } from '@/components/ui/charts/plotly/PlotlyHelpers';

// gives access to unit system, plotly chart
import ChartMiddlewareVue from '@/components/ui/charts/middleware/PlotlyChartMiddleware.vue';
import { ChartTypeEnum } from '@/components/ui/charts/ChartHelpers';

export default {
  name: 'ContactReport',

  extends: ChartMiddlewareVue,

  data() {
    const scatterChartLayout = cloneDeep(Layouts.scatterChart);
    return {
      adjustRanges: true,
      chartType: ChartTypeEnum.CONTACT_REPORT.type,
      strikeZoneModel: new GenericStrikeZone(),
      plotlyLayoutExtended: {
        ...scatterChartLayout,
        xaxis: {
          ...scatterChartLayout.xaxis,
          zeroline: false,
        },
        yaxis:{
          ...scatterChartLayout.yaxis,
          zerolinewidth: 1.5,
        },
      },
    };
  },

  methods: {
    mapSeriesNames(item) {
      if (!item.hasOwnProperty('key')) {
        return item;
      }
      let nameMapped = '';

      // todo - translate, enum, inject
      switch (item.key) {
        case 'strikeZone':
          nameMapped = 'Strike Zone';
          break;

        case 'strikeZoneSectors':
          nameMapped = 'Sectors';
          break;

        case 'grassLine':
          nameMapped = 'Grass Line';
          break;

        case 'foulLine':
          nameMapped = 'Foul Line';
          break;

        case 'hiddenSeries':
          nameMapped = ' ';
          break;

        default:
          nameMapped = Providers.EnumValueProvider.getValue(item.key, this.enumSet, this.enumSet.Unclassified.key).name;
          break;
      }

      if (nameMapped) {
        item.name = nameMapped;
      }

      return item;
    },
    seriesExtended(series) {
      let strikeZoneOutline = Providers.StrikeZoneOutlineSeriesProvider(this.strikeZoneModel, false);
      let sectors = Providers.StrikeZoneSectorsGridSeriesProvider(this.strikeZoneModel, false);
      let chartSeries = new Entities.ChartSeries([strikeZoneOutline, sectors]);

      return chartSeries.concat(series);
    },
    customMapping(converted) {
      this.plotlyData = converted.map(this.mapDefaultTraceSettings);

      let symbol = this.unitSymbolX(this.unitSystem.system);

      this.plotlyLayoutExtended.title.text = this.$vuetify.lang.t('$vuetify.reports.contactReportChart');
      this.plotlyLayoutExtended.xaxis.title = `${this.xAxisTitle}, ${symbol}`;
      this.plotlyLayoutExtended.yaxis.title = `${this.yAxisTitle}, ${symbol}`;
      this.plotlyLayoutExtended.colorway = this.getColorsFromSeries(this.plotlyData);
    },
  },
};
